import { TimeLine as TimeLineTemplate } from '@/organisms';

import type { TypeTimeLineProps } from '@/organisms/timeline';

const TimeLine = ({
  id,
  bloque,
  className,
}: {
  id: string;
  bloque: {
    fieldGroupName: string;
    title: string;
    cards: TypeTimeLineProps[];
  };
  className?: string;
}) => (
  <section id={id} className={`px-4 py-4 md:py-8 ${className}`}>
    <div className="u-wrapper flex flex-col gap-4">
      <TimeLineTemplate {...bloque} />
    </div>
  </section>
);

TimeLine.displayName = 'TimeLine';
export default TimeLine;
