import { useEffect, useState } from 'react';

type UseMatchMediaProps = {
  query: string;
  onChange?: (ev: MediaQueryListEvent) => void;
};

type UseMatchMedia = {
  matches: boolean;
};

export const useMatchMedia = ({
  query,
  onChange,
}: UseMatchMediaProps): UseMatchMedia => {
  const initialMatch = () => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState(initialMatch);

  useEffect(() => {
    const handleEvent = (ev: MediaQueryListEvent) => {
      setMatches(ev.matches);

      // The callback needs to be wrapped with useCallback
      onChange && onChange(ev);
    };

    const mediaQueryList = window.matchMedia(query);

    mediaQueryList.addEventListener('change', handleEvent);

    return () => mediaQueryList.removeEventListener('change', handleEvent);
  }, [onChange, query]);

  return {
    matches,
  };
};
